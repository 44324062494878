import _ from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/invoicesGas';
import {fetchFilters} from '../../actions/invoices';
import { debug } from '../../utils/debug';
import { regional_settings } from '../../constants';
import { locale_code } from '../../constants/i18n';
import { i18n, dayjs } from "../../config";

import { ContentHeader } from '../ContentHeader';

import { Notification } from '../Notification';
import { LoadingAnimation } from '../LoadingAnimation';

import { BarChart } from '../Chart';
import { ArrowForward, ArrowBack, ErrorOutline, GetApp } from '@mui/icons-material';
import { CircularProgress, Button, Dialog, DialogContent, DialogTitle, DialogActions, Switch, Alert, Skeleton, Box, Typography } from '@mui/material';
import { SmartTable } from '@gisce/oficina-virtual-components';
import Settings from "../../settings";

import ChartSkeleton  from "../Chart/ChartSkeleton";
import Filter from "../Filter/Filter";
import { DownloadButton } from "./DownloadButton";

function mapStateToProps(state) {
    return {
        data: state.invoices.gas,
        invoices: state.invoices['gas'].items,
        itemsOffset: state.invoices['gas'].offset_items,
        itemsPage: state.invoices['gas'].page_items,
        itemsTotal: state.invoices['gas'].total_items,
        token: state.auth.token,
        loaded: state.invoices['gas'].loaded,
        isFetching: state.invoices['gas'].isFetching,
        message_text: state.invoices['gas'].message_text,
        filterContract: state.contractsGas.filterContract,
        toggleLeft: false,
        download_status: state.invoices['gas'].download_status,
        filters: state.invoices.filters,
        errors: state.invoices.errors
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            invoices: bindActionCreators(actionCreators, dispatch),
            invoicesElec: bindActionCreators({fetchFilters}, dispatch)
        }
    }
}

const style = {
    buttonAdd: {
        marginRight: 20,
    },
    buttonPosition: {
        textAlign: 'right',
    },
    table: {
        marginTop: 20,
    },
    chart: {
        marginBottom: 50,
    },
    aggregationsCenter: {
        display: 'flex',
    },
    toggle: {
      marginTop: 7,
      marginLeft: 12,
    },
    labelToggle: {
        marginTop: 7,
        marginLeft: 7,
    },
};


class InvoicesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message_text: null,
            contractId: props.params ? props.params.contractId : null,
            toggleLeft: false,
            itemsOffset: 0,
            itemsPage: _.get(Settings, "invoices.itemsPerPage", 100),
            initialFilters: [],
            adaptedData: this.JSON_to_arrays([], true),
            chartData: this.chartInvoices([])
        };
        dayjs.locale(locale_code);
    }

    componentDidMount() {
        this.props.actions.invoicesElec.fetchFilters(this.props.token);
        // if(this.props.match.params?.contractName){
        //     this.setState({
        //       initialFilters: 
        //         [{
        //           category: { type: "autocomplete", renderText: "Contracte", searchField: "polissa_id.name" },
        //           value: [this.props.match.params?.contractName], 
        //           type: 'contains'
        //         }]
        //     });
        // } else if (this.props.isTabbed) {
        if (this.props.isTabbed) {
            if (!this.props.invoices || this.props.invoices?.length === 0) {
                this.fetchData();
            } else {
                this.setState({
                    adaptedData: this.JSON_to_arrays(this.props.invoices, false),
                    chartData: this.chartInvoices(this.props.invoices)
                });
            }
          } else {
            this.fetchData(); 
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loaded !== this.props.loaded) {
            this.setState({adaptedData: this.props.loaded ? this.JSON_to_arrays(this.props.invoices, false) : this.JSON_to_arrays([], true)});
            this.props.loaded && this.props.invoices && this.setState({chartData: this.chartInvoices(this.props.invoices) });
        }
    }

    fetchData(initial = true, offset = 0, filters = this.props.data.filters.currentFilter, itemsPage = this.state.itemsPage) {
        const token = this.props.token;
        this.props.actions.invoices.fetchInvoices(token, initial, offset, filters, itemsPage);
    }

    toggleRender = (event, status) => {
        this.setState({
            toggleLeft: status,
            message_open: false,
        });
    };

    refreshData() {
        this.fetchData(false);
        this.setState({
            message_open: true,
        });
    }

    previousPage = () => {
        let newOffset = this.props.itemsOffset - this.props.itemsPage;
        if (newOffset < 0) { newOffset = 0 };
        this.setState({itemsOffset: newOffset});
        this.fetchData(false, newOffset);
    };

    nextPage = () => {
        const newOffset = this.state.itemsOffset + this.state.itemsPage;
        this.setState({itemsOffset: newOffset});
        this.fetchData(false, newOffset);
    };

    applySearchFilter = (filters, itemsPage) => {
        this.fetchData(false, this.state.itemsOffset, filters, itemsPage);
        this.setState({itemsPage});
    }

    chartInvoices(invoices) {
        let components = {};

        const number_of_months = 12;

        // Initialize last 3 years
        const max_years = 3;
        const current_year = dayjs().year();
        const init_year = current_year - max_years;

        let energy_per_year = {};
        let amount_per_year = {};
        for (let year=init_year; year <= current_year; year++) {
            energy_per_year[year] = [];
            amount_per_year[year] = [];

            // Assign the 12 months
            for (let i=0; i<number_of_months; i++) {
                energy_per_year[year].push({'name': i, 'total':0});
                amount_per_year[year].push({'name': i, 'total':0});
            }
        }

        invoices && invoices.forEach((invoice) => {
            const end_date = dayjs(invoice.end_date);

            // Extract the date
            const month = end_date.month();
            const year = end_date.year();
            const year_lite = year - 2000;

            const sign = invoice.rectifirectificative_type === 'A' ? -1 : 1

            const amount = (parseFloat(invoice.amount_total) * sign);
            const energy = (parseFloat(invoice.energy_consumed) * sign);
            const contract = invoice.contract.name;

            // Ensure initialize correctly components with all CUPS
            if (!(contract in components)) {
                components[contract] = {
                    'title': contract,
                }
            }

            if(amount_per_year[year][month][contract]) {
                // Add the energy and ammount
                energy_per_year[year][month][contract] += energy;
                amount_per_year[year][month][contract] += amount;
            } else {
                // Set the energy and amount
                energy_per_year[year][month][contract] = energy;
                amount_per_year[year][month][contract] = amount;
            }

            // Override title by default by shorted mont and the year
            amount_per_year[year][month]['name'] = `${end_date.format("MMM")}'${end_date.format("YY")}`;
            energy_per_year[year][month]['name'] = `${end_date.format("MMM")}'${end_date.format("YY")}`;
        });

        let final_amount = [];
        let final_energy = [];
        for (let year=init_year; year <= current_year; year++) {
            for (let month=0; month < number_of_months; month++) {
                //Select just non-empty elements
                amount_per_year[year][month].total = 0;
                if (Object.keys(amount_per_year[year][month]).length > 2) {
                    // Calculate totals for each month
                    _.forEach(Object.keys(amount_per_year[year][month]), (k) => {
                        if (k !== 'total' && k !== 'name') {
                            amount_per_year[year][month].total += amount_per_year[year][month][k];
                            energy_per_year[year][month].total += energy_per_year[year][month][k];
                        }
                    });

                    // Format decimals
                    amount_per_year[year][month].total = Number(amount_per_year[year][month].total).toFixed(2);
                    energy_per_year[year][month].total = Number(energy_per_year[year][month].total).toFixed(0);

                    const the_amount = Object.assign({}, amount_per_year[year][month]);
                    const the_energy = Object.assign({}, energy_per_year[year][month]);

                    final_amount.push(the_amount);
                    final_energy.push(the_energy);
                }
            }
        }

        return {
            data: {
                energy: final_energy,
                amount: final_amount
            },
            components,
        }
    }

    handleOpenDialog = (e) => {
        e.preventDefault();
        this.setState({ dialogOpen: true });
    };
    
    handleCloseDialog = () => {
        this.setState({ dialogOpen: false });
    };

    JSON_to_arrays = (invoices = [], skeleton = false) => {
        const columns = 
        [
            {
                title: null
            },
            {
                title: i18n.t('common:text.invoices_invoice_number'),
                key: "num"
            }, {
                title: i18n.t('common:text.invoices_date'),
                key: "date"
            }, {
                title: i18n.t('common:text.invoices_period'),
                key: "period"
            }, {
                title: i18n.t('common:text.invoices_address'),
                key: "address"
            }, {
                title: i18n.t('common:text.invoices_import'),
                key: "import"
            }, {
                title: i18n.t('common:text.invoices_energy'),
                key: "energy"
            }, {
                title: i18n.t('common:text.invoices_payed'),
                key: "paid"
            }, {
                title: i18n.t('common:text.invoices_actions'),
                key: "actions"
            }
        ];

        const props = this.props;
        const that = this;
        const dataSource = skeleton ? 
            [null , null, null].map((skRow, j) => columns.map((headerEl, i) => <Skeleton variant="text" key={j+""+i}/>))
        :
            invoices ? invoices.map((invoice, index) => {
            // Invoice date
            const invoice_date = dayjs(invoice.date).format("L");

            //Start and End date (period)
            const start_date = dayjs(invoice.date).format("L");
      
            const end_date = dayjs(invoice.end_date).format("L");
      
            const period = start_date + " > " + end_date;

            const downloadButton = <DownloadButton 
                invoiceId={invoice.id} 
                type="gas"
                options={{'pdf': {name: i18n.t('common:text.invoices_view_download_button'), type: 'pdf'}}}
                onDownload={(invoiceId, reportType) => props.actions.invoices.exportInvoice(props.token, invoiceId, reportType)}
            />

            let paid = (invoice.paid)? i18n.t('common:text.invoice_paid') : i18n.t('common:text.invoice_not_paid');
            if (invoice.paid && invoice.rectificative_type === 'A') {
                paid = i18n.t('common:text.invoice_refunded');
            }
            if (invoice.amount_debt) {
                paid = <strong>{i18n.t('common:text.invoices_not_payed')}</strong>;
                
                // We enable a link to show the pending state history only if there
                // is more than one item to show.
                if (invoice.pending_state_history && invoice.pending_state_history.length > 1) {
                    const dialogActions = [
                        <Button
                            color={'primary'}
                            variant={'text'}
                            onClick={that.handleCloseDialog}
                        >
                            {i18n.t('common:text.invoices_dialog_close')}
                        </Button>
                    ];
                    paid = (
                        <div>
                            <Button
                                onClick={(e) => that.handleOpenDialog}
                                color='primary'
                                variant={'text'}
                                style={{padding: 0}}
                            >
                                {paid}
                            </Button>
                            <Dialog
                                open={that.state.dialogOpen || false}
                                onClose={that.handleCloseDialog}
                            >
                                <DialogTitle>
                                    {i18n.t('common:text.invoices_dialog_title')}
                                </DialogTitle>
                                <DialogContent>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{i18n.t('common:text.invoices_dialog_table_date')}</th>
                                                <th>{i18n.t('common:text.invoices_dialog_table_description')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoice.pending_state_history.map((i) => (
                                                <tr>
                                                    <td>{dayjs(i.change_date).format("L")}</td>
                                                    <td>{i.pending_state.ov_description}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </DialogContent>
                                <DialogActions>
                                    {dialogActions}
                                </DialogActions>
                            </Dialog>
                        </div>
                    );
                }
            }


            const values = {
                id: index,
                num: invoice.number,
                date: invoice_date,
                period: period,
                address: invoice.cups.street,
                import: invoice.amount_total_printable,
                energy: `${invoice.energy_consumed.toLocaleString(locale_code)} ${regional_settings.energy_unit}`,
                paid: paid,
                actions: downloadButton,
            };

            return values;
        }) : [];

        return {
            columns,
            dataSource
        };
    }

    render() {
        const { invoices, itemsPage, itemsTotal, itemsOffset, isFetching } = this.props;
        const { toggleLeft } = this.state;

        const onToggle = this.toggleRender;
        const the_toggle = (
            <div id="togglePicture" style={style.aggregationsCenter}>
                <div style={style.labelToggle}>
                    <span style={{fontWeight: toggleLeft ? "initial" : "bold"}}>
                        {i18n.t('common:text.amount')}
                    </span>
                </div>
                <div id="toogleElement">
                    <Switch onChange={onToggle} checked={toggleLeft} />
                </div>
                <div style={style.toggle}>
                    <span style={{fontWeight: !toggleLeft ? "initial" : "bold"}}>
                        {i18n.t('common:text.energy')}
                    </span>
                </div>
            </div>
        );

        const unit = (!toggleLeft) ? regional_settings.currency : regional_settings.energy_unit;

        const chart = <BarChart
            data={this.state.toggleLeft ? this.state.chartData.data.energy : this.state.chartData.data.amount }
            components={this.state.chartData.components}
            unit={unit}
        />

        const itemsStart = itemsOffset;
        const itemsEnd = Math.min(itemsStart + itemsPage, itemsTotal);

        const invoiceCategories = [
            { type: "text",         category: "name",     title: i18n.t("common:text.filter_invoices_invoice"),      renderText: i18n.t("common:text.filter_invoices_invoice"), helper: "F123456", searchField: "number" },
            { type: "autocomplete", category: "contract", title: i18n.t("common:text.filter_invoices_contract"),     renderText: i18n.t("common:text.filter_invoices_contract"), helper: "12345", additionalData: this.props.data.filters.data?.contracts ?? [], searchField: "polissa_id.name" },
            { type: "autocomplete", category: "vat",      title: "NIF",                                              renderText: "NIF",  additionalData: this.props.data.filters.data?.vats ?? [], searchField: "invoice_id.partner_id.vat"},
            { type: "date",         category: "date",     title: i18n.t("common:text.filter_invoices_date_title"),   renderText: i18n.t("common:text.filter_invoices_date_renderText"), searchField: "date_invoice" },
            { type: "date",         category: "period",   title: i18n.t("common:text.filter_invoices_period_title"), renderText: i18n.t("common:text.filter_invoices_period_renderText"), searchField: ["data_inici", "data_final"] },
            { type: "autocomplete", category: "cups",     title: "CUPS",                                             renderText: "CUPS", additionalData: this.props.data.filters.data?.cups ?? [], searchField: "cups_id.name" },
            { type: "autocomplete", category: "address",  title: i18n.t("common:text.filter_invoices_address"),      renderText: i18n.t("common:text.filter_invoices_address"), additionalData: this.props.data.filters.data?.addresses ?? [], searchField: "cups_id.direccio" },
            { type: "numeric",      category: "import",   title: i18n.t("common:text.filter_invoices_import_title"), renderText: i18n.t("common:text.filter_invoices_import_renderText"), units: "€", searchField: "amount_total" },
            // { type: "numeric",      category: "energy",   title: i18n.t("common:text.filter_invoices_energy_title"), renderText: i18n.t("common:text.filter_invoices_energy_renderText"), units: "kWh", searchField: "energia_kwh" },
            { type: "select",       category: "paid",     title: i18n.t("common:text.filter_invoices_state"),        renderText: i18n.t("common:text.filter_invoices_state"), searchField: "state", additionalData: [
                {label: i18n.t("common:text.invoice_paid"), search: "paid"}, {label: i18n.t("common:text.invoice_not_paid"), search: "open"}
              ] 
            }
        ]

        return (
            <>
                <Notification
                    message={this.props.message_text}
                    time={6000}
                    open={true}
                />

        		<ContentHeader
        		    title={i18n.t('common:text.invoices_view_title_gas')}
        		    addButton={false}

        		    refreshButton={true}
        		    refreshClickMethod={() => this.refreshData()}
                />
                {this.props.loaded &&
                    <div style={style.table}>

                        <Box mb={2}>
                            <Typography variant={"h6"}>
                                {i18n.t('common:text.invoices_contracts_chart')}
                            </Typography>
                        </Box>
                        {this.props.loaded ? (
                            invoices && invoices.length > 0 ? (
                                <>
                                    <div>{the_toggle}</div>
                                    <div style={style.chart}>{chart}</div>
                                </>
                            ) : (
                                <Box mb={2}>
                                    {this.props.errors ? 
                                        <Alert severity="warning">{i18n.t("common:text.invoices_error_fetching")}</Alert>
                                        :
                                        <Typography variant={"h5"}>
                                            {i18n.t('common:text.invoices_view_empty_list')}
                                        </Typography>
                                    }
                                </Box>
                            )
                        ) : (
                            <>
                                <Skeleton animation={false}>{the_toggle}</Skeleton>
                                <ChartSkeleton />
                            </>
                        )}
                        {invoices && invoices.length > 0 &&
                            <>
                                <Filter
                                    categories={invoiceCategories}
                                    applySearchFilter={this.applySearchFilter}
                                    disableApply={!this.props.loaded}
                                    initialFilters={this.state.initialFilters}
                                />
                                <SmartTable
                                    columns={this.state.adaptedData?.columns ?? []}
                                    dataSource={this.state.adaptedData?.dataSource ?? []}
                                    loading={!this.props.loaded}
                                    onRowStatus={this.getStatusForItem}
                                    loadingComponent={<LoadingAnimation />}
                                />
                            </>
                        }
                    </div>
                }

                {itemsPage < itemsTotal && (
                    <div style={{ textAlign: "center" }}>
                        {itemsStart > 0 && (
                            <Button
                                variant={"text"}
                                onClick={() => this.previousPage()}
                                disabled={isFetching}
                            >
                                {<ArrowBack />}
                                {i18n.t("common:text.pagination_back")}
                            </Button>
                        )}
                        <span>
                            {i18n.t("common:text.pagination_showing", 
                                {init: itemsStart, end: itemsEnd, ammount: itemsTotal})}
                        </span>
                        {itemsEnd < itemsTotal && (
                            <Button
                                variant={"text"}
                                onClick={() => this.nextPage()}
                                disabled={isFetching}
                            >
                                {i18n.t("common:text.pagination_next")}
                                {<ArrowForward />}
                            </Button>
                        )}
                    </div>
                )}


                {(itemsPage < itemsTotal) &&
                    <div style={{ textAlign: "center" }}>
                        {(itemsStart > 0) &&
                            <Button
                                variant={'text'}
                                onClick={() => this.previousPage()}
                                disabled={isFetching}
                            >
                                {<ArrowBack />}
                                {i18n.t("common:text.pagination_back")}
                            </Button>
                        }
                        <span>
                            {i18n.t("common:text.pagination_showing", 
                                {init: itemsStart, end: itemsEnd, ammount: itemsTotal})}
                        </span>
                        {itemsEnd < itemsTotal &&
                            <Button
                                variant={'text'}
                                onClick={() => this.nextPage()}
                                disabled={isFetching}
                            >
                                {i18n.t("common:text.pagination_next")}
                                <ArrowForward />
                            </Button>
                        }
                    </div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList);
